@import "mixins"
.cookieBanner
  position: fixed
  bottom: calc(var(--menuHeight) + 10px)
  left: 50%
  transform: translateX(-50%)
  width: 340px
  max-width: 90%
  min-height: 180px
  padding: 20px 16px
  z-index: 999999
  border-radius: 12px
  border-top: 1px solid rgb(var(--particularColor))
  background: var(--cookie_bannerBg)
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.4)
  @include flex
  flex-direction: column
  button
    min-width: 160px
    height: 36px
    font-size: 14px
    margin-top: auto

.cookieMessage
  > p
    font-size: 14px
    text-align: center
    line-height: 18px
    margin-bottom: 16px
    > a
      margin-left: 4px
      text-decoration: underline
      text-underline-offset: 4px

  :global(h4)
    color: #fff
    font-size: 16px
    margin: 0 0 12px
    text-align: center
